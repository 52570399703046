import React, { useEffect, useState } from 'react';
import './styles.css';
import { setTitle } from './utils';
import { submitContactForm } from './APICall.js';



function Contact() {
  useEffect(() => {
    setTitle("Acacia Electrical LLC");
  }, []);


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: ""
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...formErrors };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email.trim())) {
      newErrors.email = "Valid email is required";
      valid = false;
    }

    const phoneRegex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone.trim())) {
      newErrors.phone = "Valid phone number is required";
      valid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await submitContactForm(formData);
        setIsSubmitted(true);
      } catch (error) {
        console.error("Submission failed", error);
      }
    } else {
      console.log("Form has errors. Please fix them.");
    }
  };

  return (
    <div>
      {isSubmitted ? (
        <div className="thank-you-message">
          <h2>Thank You for Submitting!</h2>
          <p>We will get back to you soon.</p>
        </div>
      ) : (
       
          <div className="contact">
            <div className="contact-box">
              <h2>Submit Contact Information</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="name"><b>Name:</b></label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder='Enter Your Name'
                    required
                  />
                  <div className="error">{formErrors.name}</div>
                </div>

                <div className="form-group">
                  <label htmlFor="email"><b>Email:</b></label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder='Enter Your Email'
                    required
                  />
                  <div className="error">{formErrors.email}</div>
                </div>

                <div className="form-group">
                  <label htmlFor="phone"><b>Phone Number:</b></label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder='Enter Your Phone Number'
                    required
                  />
                  <div className="error">{formErrors.phone}</div>
                </div>

                <div className="form-group">
                  <label htmlFor="message"><b>Message</b></label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder='Type Your Message Here'
                    required
                  ></textarea>
                  <div className="error">{formErrors.message}</div>
                </div>

                <button type="submit" className="submit-button">Submit</button>
              </form>
            </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
