import { API } from "aws-amplify";
import { createCustomerContact } from './graphql/mutations.js';



export const submitContactForm = async (contactData) => {
  try {
    const newCustomerContact = await API.graphql({
      query: createCustomerContact,
      variables: {
        input: contactData
      }
    });

    console.log("New customer contact created:", newCustomerContact);

    // Handle the response as needed

  } catch (error) {
    console.error("Error creating customer contact:", error);
    // Handle the error as needed
  }
}; 