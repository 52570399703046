/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomerContact = /* GraphQL */ `
  mutation CreateCustomerContact(
    $input: CreateCustomerContactInput!
    $condition: ModelCustomerContactConditionInput
  ) {
    createCustomerContact(input: $input, condition: $condition) {
      id
      name
      email
      phone
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerContact = /* GraphQL */ `
  mutation UpdateCustomerContact(
    $input: UpdateCustomerContactInput!
    $condition: ModelCustomerContactConditionInput
  ) {
    updateCustomerContact(input: $input, condition: $condition) {
      id
      name
      email
      phone
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerContact = /* GraphQL */ `
  mutation DeleteCustomerContact(
    $input: DeleteCustomerContactInput!
    $condition: ModelCustomerContactConditionInput
  ) {
    deleteCustomerContact(input: $input, condition: $condition) {
      id
      name
      email
      phone
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
