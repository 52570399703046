import React, { useEffect } from 'react';
import './styles.css';
import { setTitle } from './utils';

function About() {
  useEffect(() => {
    setTitle("Acacia Electrical LLC");
  }, []);
  return (
    <div className="about-page">
      <h1 className='titles'>About Us</h1>


      <div className="quote">
        <div className='Sal'>
          <img src="/images/sal.png" alt='Sal' className='Sal-photo' />
        </div>
            <div className="quote-boxs">
            <h2 className='intro-about'> <i>"At Acacia Electric, we believe in building lasting relationships with our clients through quality workmanship and trustworthy service."</i></h2>
            </div>
          </div>

      
      
    </div>       

  );
}

export default About;