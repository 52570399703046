import React from 'react';
import './styles.css';

function Footer() {
  return (
    <footer>
      <div className="footer">
      <img src="/images/AELLClogo3.png" alt="logo" className="logo" />

      <div className="foot-divider">
        <div className="socials">
          <h3 className="find">Find us on social media</h3>
          <a href="https://www.instagram.com/acaciaelectric/?igsh=MW41amQ5aGtvOHNjNA%3D%3D" className="social-link"target="_blank" rel="noopener noreferrer">
            <img src="/images/ig.png" alt="Instagram" className="instagram" />
          </a>

          <a href="tel:+12062297007" className="social-link">
              <img src="/images/phone.png" alt="Phone" className="phone" />
              </a>

        

          
          {/*}
          <a href="https://www.example.com" className="social-link">
            <img src="/images/meta2.png" alt="Meta" className="meta" />
          </a>
          <a href="https://www.example.com" className="social-link">
            <img src="/images/x1.png" alt="X" className="x" />
          </a>
          <a href="https://www.linkedin.com" className="social-link">
            <img src="/images/in2.png" alt="LinkedIn" className="in" />
          </a>
          */}

        </div>

        <div className="company">
          <h3>Company</h3>
          <a href="/about" className="social-link"><p>About Us</p></a>
        </div>
      </div>
      </div>
    </footer>
  );
} 

export default Footer;
