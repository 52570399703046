import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setTitle } from './utils';

function Menu() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setTitle("Acacia Electrical LLC");

    // Function to close the drawer if clicked outside
    const closeDrawerOnClickOutside = (event) => {
      if (isDrawerOpen && !event.target.closest('.menu-links') && !event.target.closest('.hamburger')) {
        setDrawerOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', closeDrawerOnClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', closeDrawerOnClickOutside);
    };
  }, [isDrawerOpen]);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <nav className="menu">
      <button className="hamburger" onClick={toggleDrawer}>
        <div></div>
        <div></div>
        <div></div>
      </button>

      <div className="logo-and-title">
        <img src="/images/otherlogo.png" alt="otherlogo" className="otherlogo" />
        <h1 className="should">Electrical, the way it should be.</h1>
      </div>

      <div className={`menu-links ${isDrawerOpen ? 'open' : ''}`}>
      <img src="/images/otherlogo.png" alt="otherlogo" className="otherlogo2" />
        <Link to="/" onClick={toggleDrawer} className='link-color'>Home</Link>
        <Link to="/about" onClick={toggleDrawer} className='link-color'>About</Link>
        <Link to="/projects" onClick={toggleDrawer}className='link-color'>Services</Link>
        <Link to="/contact" onClick={toggleDrawer} className='contact-button'>Contact</Link>
        <p className='phone'>206-229-7007</p>
      </div>
    </nav>
  );
}

export default Menu;

