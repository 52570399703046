import React, { useEffect } from 'react';
import './styles.css';
import { setTitle } from './utils';

function Projects() {
  useEffect(() => {
    setTitle("Acacia Electrical LLC");
  }, []);
  return (
    <div>
      <div className="projects">

      <h1 className='titles'>Check out our past and current projects</h1>

        <div className="project">
          <div className="project-image-container">
            <img src="/images/project2A.jpg" alt="Before Project 2" className="after-image" />
            <img src="/images/project2B.jpg" alt="After Project 2" className="before-image" />
          </div>
          <div className="project-description">
            <h1>Lighting</h1>
            <p>Updated recessed lighting with multiple three way switches. Providing a more vibrate and bright feel.  </p>
          </div>
        </div>

        <div className="project">
          <div className="project-image-container">
            <img src="/images/tesla.jpg" alt="Before Project 3" className="after-image" />
            <img src="/images/tesla.jpg" alt="After Project 3" className="before-image" />
          </div>
          <div className="project-description">
            <h1>EV Charging</h1>
            <p>Don't wait for a charging station. Plug in at the comfort of your home.</p>
          </div>
        </div>

        <div className="project">
          <div className="project-image-container">
            <img src="/images/project3A.jpg" alt="Before Project 3" className="after-image" />
            <img src="/images/project3B.jpg" alt="After Project 3" className="before-image" />
          </div>
          <div className="project-description">
            <h1>Panel Upgrades</h1>
            <p>Updated and replacement of old panels. Allowing for future installations and upgrades to home. </p>
          </div>
        </div>

        <div className="project">
          <div className="project-image-container">
            <img src="/images/meterBefore.jpg" alt="Before Project 1" className="after-image" />
            <img src="/images/meterAfter.jpg" alt="After Project 1" className="before-image" />
          </div>
          <div className="project-description">
            <h1>Meter Replacements</h1>
            <p>200amp service upgrade, from 60 amp previous service. Installation of a new meter and disconnect up to current NEC code.  </p>
          </div>
        </div>

      </div> 
    </div>
  );
}

export default Projects;
