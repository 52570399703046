import React, { useEffect } from 'react';
import './styles.css';
import { setTitle } from './utils';
import { Link } from 'react-router-dom';


function Home() {
  useEffect(() => {
    setTitle("Acacia Electric");

    const handleScroll = () => {
      const listItems = document.querySelectorAll('.residential li, .commercial li, .renovations li');
      listItems.forEach(li => {
        if (li.getBoundingClientRect().top < window.innerHeight) {
          li.classList.add('fade-in');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger once on component mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    
  }, []);
  return (
    <div>
      <div className="intro">
        <h1 className="intro-header"><i>Serving the Greater Seattle Area</i></h1>
        <h2 className="intro-header">Residential & Commercial Contractor Licensed, Bonded; Insured</h2>
      </div>

      <div className="section-wrapper">
      <div className="residential">
      <img src="/images/comercialRoof.png" alt="new install" className="home-improve" />
        <div className='square'>
        <h2>Residential & Commercial</h2>
        <h3 className='res'>Power your business with our expert services.</h3>
            </div>
      </div>

      <div className="commercial">
      <img src="/images/newInstall.jpg" alt="new install" className="home-improve" />
        <div className='square'>
          <h2>New Instalation & Repair</h2>
          <h3 className='com'>Power your business with our expert commercial electrical services.</h3>
          </div>
      </div>


      <div className="renovations">
      <img src="/images/remodel.png" alt="home improvement" className="home-improve" />
        <div className='square'>
        <h2>Home Remodeling</h2>
        <h3 className='home'>Your home is your haven, let us light your vision.</h3>
            </div>
        </div>
      </div>

    
        <div className='foot'>
        <Link to="/contact" className="request-button"><i>Request a Quote</i></Link>
        </div>
      </div>

  );
}

export default Home;